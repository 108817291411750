'use client'
import React from 'react'
import Image from 'next/image'

import '@/scss/notFound.scss'

export default function NotFound() {
	return (
		<div className='not-found-page'>
			<Image
				src={'/img/contest_logo.png'}
				width={262}
				height={262}
				alt={'logo'}
			/>
			<p>Страница не найдена!</p>
			<small>
				Приложение доступно только на мобильных устройства или вы перешли по
				несуществующей ссылке!
			</small>
		</div>
	)
}
